<template>
  <div>
    <div class="video">
      <div class="video-item">
        <a href="javascript: void(0)">
          <div class="img_box">
            <img src="" alt="">
            <i class="img_icon"></i>
            <em class="tip">IPO路演</em>
          </div>
          <div class="item-info">
            <h2>xx科技首次公开发行A股网上投资者交流会</h2>
            <div class="video-item-text">
              我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是
            </div>
            <div class="item-footnote">
              <div class="footnote-scoure">某某企业（39023298）</div>
              <div class="footnote-timer">2020-12-24 08:20:12</div>
            </div>
          </div>
        </a>
      </div>
      <div class="video-item">
        <a href="javascript: void(0)">
          <div class="img_box">
            <img src="" alt="">
            <i class="img_icon"></i>
            <em class="tip">IPO路演</em>
          </div>
          <div class="item-info">
            <h2>xx科技首次公开发行A股网上投资者交流会</h2>
            <div class="video-item-text">
              我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是
            </div>
            <div class="item-footnote">
              <div class="footnote-scoure">某某企业（39023298）</div>
              <div class="footnote-timer">2020-12-24 08:20:12</div>
            </div>
          </div>
        </a>
      </div>
      <div class="video-item">
        <a href="javascript: void(0)">
          <div class="img_box">
            <img src="" alt="">
            <i class="img_icon"></i>
            <em class="tip">IPO路演</em>
          </div>
          <div class="item-info">
            <h2>xx科技首次公开发行A股网上投资者交流会</h2>
            <div class="video-item-text">
              我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是
            </div>
            <div class="item-footnote">
              <div class="footnote-scoure">某某企业（39023298）</div>
              <div class="footnote-timer">2020-12-24 08:20:12</div>
            </div>
          </div>
        </a>
      </div>
      <div class="video-item">
        <a href="javascript: void(0)">
          <div class="img_box">
            <img src="" alt="">
            <i class="img_icon"></i>
            <em class="tip">IPO路演</em>
          </div>
          <div class="item-info">
            <h2>xx科技首次公开发行A股网上投资者交流会</h2>
            <div class="video-item-text">
              我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是
            </div>
            <div class="item-footnote">
              <div class="footnote-scoure">某某企业（39023298）</div>
              <div class="footnote-timer">2020-12-24 08:20:12</div>
            </div>
          </div>
        </a>
      </div>
      <div class="video-item">
        <a href="javascript: void(0)">
          <div class="img_box">
            <img src="" alt="">
            <i class="img_icon"></i>
            <em class="tip">IPO路演</em>
          </div>
          <div class="item-info">
            <h2>xx科技首次公开发行A股网上投资者交流会</h2>
            <div class="video-item-text">
              我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是
            </div>
            <div class="item-footnote">
              <div class="footnote-scoure">某某企业（39023298）</div>
              <div class="footnote-timer">2020-12-24 08:20:12</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/video_list.less";
import { setCatalogTree } from '@/utils/store-data'
export default {
  data() {
    return {};
  },
  created(){
    setCatalogTree(this.$store)
  }
};
</script>

